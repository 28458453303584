<template>
    <div class="container mt-5">
        <div class="row ">
            <img src="../invoice/iheader.png" alt="header" width="100%" />
        </div>
        <div class="row bortr mt-2">
            <div class="col-sm-12">
                <h1 class="text-center">Internal Invoice / فاتورةداخلية
                </h1>
            </div>
        </div>
        <div class="row">

            <table class="table table-sm m-0  mb-4">
                <tbody>
                    <tr class="no-border">
                        <td>SUPPLIER NAME: {{ invoice.customer_name }}</td>
                        <td>
                            Invoice No.: {{ invoice.invoice_id }}
                        </td>
                    </tr>
                    <tr class="no-border">
                        <td> ADDRESS: {{ invoice.bank }}</td>
                        <td>
                            Invoice Date: {{ invoice.created_at | moment("DD/MM/YYYY") }}
                        </td>
                    </tr>

                    <tr class="no-border">
                        <td>TELPHONE # : {{ invoice.po }}</td>
                        <td></td>

                    </tr>
                    <tr class="no-border">
                        <td>FAX #: {{ invoice.vendor }}</td>
                        <td></td>
                    </tr>
                    <tr class="no-border">
                        <td>EMAIL ADDRESS : {{ invoice.costcenter }}</td>
                        <td></td>
                    </tr>
                    <tr class="no-border">
                        <td colspan="2">
                            <span class="float-right">
                                <vue-qrcode :value="invoice.qrcode" :options="{ width: 150 }"></vue-qrcode>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>


            <table class="table table-sm m-0  mb-4">
                <tbody>
                    <tr class="no-border">
                        <td>Account # : {{ invoice.contract_no }}</td>
                        <td>Mode of Payment: {{ invoice.invoice_type }}</td>
                    </tr>
                    <tr class="no-border">
                        <td>Swift Code : {{ invoice.contractor_no }}</td>
                        <td>ROUTING # : {{ invoice.ses_esv }}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="row">
            <table class="table table-sm">
                <tbody>
                    <tr class="no-border">
                        <td class="text-left">S#</td>
                        <td class="text-center">Description</td>
                        <td class="text-center">Qty</td>
                        <td class="text-center">Unit Price</td>
                        <td class="text-center">Curr</td>
                        <td class="text-center">Total Amount</td>
                    </tr>
                    <tr class="row-tr" v-for="(value, index) in items" :key="index">
                        <td class="text-left">{{ index + 1 }}
                        </td>
                        <td class="text-left fa-nb">
                            {{ value.item }}
                        </td>
                        <td class="text-center fa-nb">{{ value.quantity }}</td>
                        <td class="text-center fa-nb">{{ comma(value.price) }}</td>
                        <td class="text-center fa-nb">SAR</td>
                        <td class="text-center fa-nb">{{ comma(value.amount) }}</td>
                    </tr>

                    <tr class="no-border">
                        <td colspan="4" class="text-center">
                            Total
                        </td>
                        <td colspan="2" class="text-right">
                            0
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>


        <div class="row">
            <table class="table table-sm no-border">
                <tbody>
                    <tr>
                        <td class="text-right">Total Amount DUE </td>
                        <td class="text-right">{{ invoice_total_amount }}</td>
                    </tr>
                    <tr>
                        <td class="text-right"> الخدمات الفنية والإستشارية / Technical Consulting Services
                            {{ invoice.no_retention }}% </td>
                        <td class="text-right">{{ invoice_vat_amount }}</td>
                    </tr>
                    <tr>
                        <td class="text-right">Net Amount (SAR) </td>
                        <td class="text-right">{{ invoice_gross_amount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <table class="table table-sm no-border">
                <tbody>
                    <tr>
                        <td class="text-left">Amount in Words: {{ invoice.amount_words }}</td>
                        <td class="text-right"> المبلغ بالكلمات:{{ invoice.amount_words_ar }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <img src="..//invoice/ifooter.png" alt="header" width="100%">
        </div>
    </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
    name: "InternalInvoice",
    components: {
        VueQrcode: VueQrcode,
    },
    data: function () {
        return {
            selected_expat: "progress",
            option_expat: [
                {
                    text: "Progress",
                    value: "progress",
                },
                {
                    text: "Retention",
                    value: "retention",
                },
                {
                    text: "Other",
                    value: "other",
                },
            ],
            selected_approval: "yes",
            option_approval: [
                {
                    text: "Yes",
                    value: "yes",
                },
                {
                    text: "No",
                    value: "no",
                },
            ],
            invoice: {},
            items: [],
            invoice_total_amount: 0,
            invoice_vat_amount: 0,
            invoice_gross_amount: 0,
            invoice_rentention_amount: 0,
            invoice_net_amount: 0,
            // amount_words: "",
            hc_address: "",
        };
    },
    mounted() {
        this.retrieve(this.$route.params.id);
    },
    methods: {
        comma(data) {
            //  return data
            return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
            });
        },
        retrieve(id) {
            HRMService.getOrderItems(id)
                .then((response) => {
                    this.invoice = response.data["sales"][0];
                    this.items = response.data["items"];
                    this.invoice_total_amount = this.comma(this.invoice.total_amount);
                    this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
                    this.invoice_gross_amount = this.comma(this.invoice.gross_amount);
                    this.invoice_net_amount = this.comma(this.invoice.net_amount);
                    // this.amount_words = converter.toWords(this.invoice_net_amount);

                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};


</script>
<style scoped>
.word {
    text-transform: capitalize;
}

.fa-bold {
    font-weight: bold;
}

.float-center {
    text-align: center;
}

.table tr td {
    font-size: 14px !important;
    font-weight: bold;
    margin: 0;
    padding: 0px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.bortr {
    border: black solid 1px;
}

.no-border {
    border-left: black solid 1px;
    border-right: black solid 1px;
}

.right-border {
    border-right: black solid 1px !important;
}

.top-boder {
    border-top: solid 1px black !important;
}

.bottom-boder {
    border-bottom: solid 1px black !important;
}

.table td {
    border: solid 1px black;
}

.td-3 {
    border-right: solid 1px black !important;
}

.row-tr td {
    border: black solid 1px;
}

.fa-nb {
    font-weight: 300 !important;
}

.f-14 {
    font-size: 14px !important;
}

.image {
    width: 100px;
    align-self: center;
}

.header-arabic {
    align-self: flex-end;
    font-size: 15px;
    width: 300px;
    border: 1px solid #000;
}

.al-right {
    align-self: flex-end;
}

.df {
    display: flex;
    align-items: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-sm {
    font-size: 10px;
}

.text-md {
    font-size: 14px;
}

input[type="checkbox"]:checked {
    background-color: black;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid black;
    border-radius: 3px;
    outline: none;
    /* background-color: black; */
    cursor: pointer;
}
</style>